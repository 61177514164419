// Hero.js
import React from 'react';
import '../App.css';



const Footer = () => {
    return (
        <div className='footer'>
            <div className='social-container'>
                <a href="https://www.webtoons.com/creator/NatashaBerlin"><img className='webtoon' src='/assets/images/webtoon.png' alt='webtoon'/></a>
                <a href="https://www.instagram.com/potofgoldcomic?igsh=MWxhNGswdWk0b2pzMw%3D%3D&utm_source=qr"><img className='instagram' src='/assets/images/instagram.png' alt='instagram'/></a>
                <a href="https://discord.gg/Ucg2RYHuMz"><img className='discord' src='/assets/images/discord.png' alt='discord'/></a>
            </div>
        </div>
    );
};

export default Footer;
