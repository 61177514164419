// Hero.js
import React from 'react';
import '../App.css';

const Hero = () => {
    
    return (
        <div className="hero">
            <div className="hero-content">
                <a href="https://www.webtoons.com/en/canvas/pot-of-gold/list?title_no=128670"><img className='lineup' src='/assets/images/lineup.jpg' alt="lineup" /></a>
                <div className='hero-text'>
                    <h1>Pot of Gold</h1>
                    <p>
                        Twin sisters reuniting after years spent apart<br></br>
                        A disgraced director trying to prove himself<br></br>
                        A fearful boy running from an unknown threat<br></br>
                        And then... a fire<br></br>
                    </p>
                        <br></br>
                    <p className='italic'>    
                        <a href='https://www.webtoons.com/en/canvas/pot-of-gold/list?title_no=128670' style={{color:'#e3546c'}}>Read for free on webtoon</a><br></br>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Hero;
