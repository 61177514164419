// Navbar.js
import React from 'react';
import '../App.css';


const Navbar = () => {

  return (
    <div className="navbar">
      <div className='spacer'></div>
      <div className='logo-container'>
        <img className='logo' src='/assets/images/logo.png' alt="Logo" />
      </div>
      <div className='text-container'>
        <h1>&nbsp; Stories that heal <br></br> Stories you can feel</h1>
      </div>
      <div className='spacer'></div>
    </div>
  );
};

export default Navbar;
